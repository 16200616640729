import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/proxy-app",
  "date": "26th October 2021",
  "title": "Proxy App for Skpr",
  "wip": true,
  "draft": true,
  "summary": "A lightweight container that can be deployed as a Skpr application to forward traffic to secure backends inside and out of the Skpr hosting platform.",
  "author": "Karl Hepworth",
  "tag": "Project",
  "tagColor": "blue",
  "tags": [{
    "name": "project"
  }, {
    "name": "skpr"
  }, {
    "name": "proxy"
  }, {
    "name": "networking"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Introducing `}<a parentName="p" {...{
        "href": "https://github.com/skpr/proxy-app"
      }}>{`proxy-app`}</a>{`, a lightweight proxy for exposing endpoints that require authentication.`}</p>
    <p>{`Now that we `}<a parentName="p" {...{
        "href": "/blog/elasticsearch-overview"
      }}>{`support ElasticSearch`}</a>{`, we needed a way to expose
its private endpoint to external applications whilst providing a layer of authorisation right from your Skpr project.
`}<img parentName="p" {...{
        "src": "/images/posts/proxy-app/overview.png",
        "alt": "Diagram showing architecturaldesign of proxy application"
      }}></img></p>
    <h2>{`Why use this in your project?`}</h2>
    <p>{`If you've got a private endpoint in your project and wish to expose it publicly in a controlled way, you're in luck.
The Skpr proxy app will allow for external apps to access the private endpoint in a public location which can be
consumed by other applications - such as a Solr index, an ElasticSearch instance etc. Control mechanisms are currently
limited to HTTP Basic authentication and any applicable routing rules in your project's settings.`}</p>
    <p>{`The proxy app is easily configurable via the `}<inlineCode parentName="p">{`skpr`}</inlineCode>{` command-line tool, so managing the app is as easy as other Skpr apps.`}</p>
    <h2>{`How can I use it?`}</h2>
    <p>{`Let's look at how two ways of using this proxy can assist:`}</p>
    <h3>{`Docker`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ docker run --rm \\
  -p 7000:7000 \\
  -e PROXY_APP_ADDR=:7000 \\
  -e PROXY_APP_ENDPOINT=https://www.skpr.com.au \\
  -e PROXY_APP_USERNAME=""
  -e PROXY_APP_PASSWORD= ""
  --name skpr-proxy \\
  skpr/proxy-app
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl -Isl http://localhost:7000 -H "Host: www.skpr.com.au" | grep HTTP
HTTP/1.1 200 OK
`}</code></pre>
    <h3>{`Docker compose`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`services:
  proxy:
    image: skpr/proxy-app
    environment:
      - "PROXY_APP_ADDR=:30881"
      - "PROXY_APP_ENDPOINT=https://www.skpr.com.au"
      - "PROXY_APP_USERNAME=''"
      - "PROXY_APP_PASSWORD= ""
    ports:
    - 30881:30881
`}</code></pre>
    <p><strong parentName="p">{`See it in action`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ docker-compose run cli
Creating project_cli ... done
root@9e0a67879b16:/app# curl --HEAD proxy:30881 -H "Host: www.skpr.com.au" | grep HTTP
HTTP/1.1 200 OK
`}</code></pre>
    <h2>{`What's next?`}</h2>
    <p>{`Any OCI-compliant container-based solution will be able to support our proxy.`}</p>
    <p>{`It's pretty exciting to have a proxy to tunnel traffic from multiple external
endpoints to a centralized service such as search, content or something else
completely.`}</p>
    <p>{`We feel this solution would help provide customers keep control and security over their
applications whilst allowing for decoupled and other interesting application architectures.`}</p>
    <p>{`Do you have any architectural challenges you're facing? Could this new capability bring
some exciting functionality to your application? `}<a parentName="p" {...{
        "href": "#request-a-demo"
      }}>{`We'd love to hear from you`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      